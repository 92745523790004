<template>
    <div class="how">
        <HowItWorks :isLanding="false"></HowItWorks>
    </div>
</template>

<script>
export default {
    components: {
        HowItWorks: () => import('@/components/landing-page/HowItWorks')
    },
}
</script>

<style lang="scss" scoped>
</style>